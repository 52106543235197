import { Navbar } from "react-bootstrap";
import HpeLogo from "./logo.svg";
import "./Navbar.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { PiSignOutBold } from "react-icons/pi";
import { Tooltip } from "react-tooltip";

function Header() {
  return (
    <Navbar sticky="top" className="Navbar-container">
      <div className="logo">
        <img className="logo-image" src={HpeLogo} alt="HPE Logo" />
      </div>
      <div className="collapse navbar-collapse justify-content-md-center">
        <text className="brand-name">Project JYOTI</text>
      </div>
      <div className="right-corner-div">
        {/* <button className="">Log Interpretation</button> */}
        <div className="collapse navbar-collapse justify-content-md-center">
          <Authenticator>
            {({ signOut, user }) => (
              <div>
                <Tooltip id="signout-tooltip" style={{ padding: "5px" }} />
                <PiSignOutBold
                  data-tooltip-id="signout-tooltip"
                  data-tooltip-content="Sign out"
                  data-tooltip-place="bottom"
                  onClick={signOut}
                  className="sign-out-icon"
                />
              </div>
            )}
          </Authenticator>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
