import Offcanvas from "react-bootstrap/Offcanvas";
import "./ContextModal.css";
import Card from "react-bootstrap/Card";

const ContextModal = ({
    setIsModalopen,
    isModalopen,
    answerContext,
    ...props
}) => {
    const handleCloseButton = () => {
        setIsModalopen(false);
    };

    return (

        <Offcanvas show={isModalopen} onHide={handleCloseButton} {...props} className='offcanvas'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{answerContext?.length} Sources</Offcanvas.Title>
            </Offcanvas.Header>

            {answerContext.map((link) => {
                return (
                    <Card>
                        <Card.Body><a className="anchor-style" href={link} target="_blank" rel="noreferrer"> {link}</a></Card.Body>
                    </Card>
                );
            })}
        </Offcanvas>

    );
};

export default ContextModal;
