import "./App.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Header from "./views/Navbar";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LogInterpretation from "./views/LogInterpretation/LogInterpretation";
import ChatWindow from "./views/ChatWindow";

Amplify.configure(awsconfig);
function App() {

  const hideHeader = new URLSearchParams(window.location.search).get('header') === 'false';
  const router = createBrowserRouter([
    {
      path: '/',
      element: <ChatWindow />
    },
    {
      path: "/prc",
      element: <LogInterpretation />,
    },
  ])
  return (
    <div className="App">
      {!hideHeader && <Header />}
      <RouterProvider router={router} />
    </div>
  );
}

export default withAuthenticator(App);
