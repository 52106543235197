import React, { useState, useRef, useEffect } from "react";
import "./ChatWindow.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AiFillDelete } from "react-icons/ai";
//import { ThreeDots } from "react-loader-spinner";
import { IoMdSend } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { FiClipboard } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { HiOutlineViewList } from "react-icons/hi";
import { FaThumbsUp, FaThumbsDown, FaLightbulb } from "react-icons/fa";
import ContextModal from "./ContextModal";
import BouncingDotsLoader from "./BouncingDotsLoader";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneSpace as style } from 'react-syntax-highlighter/dist/esm/styles/prism';



const formatScore = (score) => {
  return score.toFixed(2);
};

const ChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [textAreaMessage, setTextAreaMessage] = useState("");
  const [sender, setSender] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatsObjectsList, setChatsObjectsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalopen, setIsModalopen] = useState(false);
  const [answerContext, setAnswerContext] = useState([]);

  const scrollableDiv = useRef(null);

  const scrollToBottom = () => {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = scrollableDiv.current.scrollHeight;
    }
  };
  const initialized = useRef(false);
  const question = new URLSearchParams(window.location.search).get("question");
  useEffect(() => {
    if (question === null || question.length === 0) {
      initialized.current = true;
    }
    if (!initialized.current) {
      handleSendMessage();
    }
    scrollToBottom();
  });


  const renderMarkdown = (response) => (
    <ReactMarkdown
      children={response}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              style={style}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        p: ({ node, ...props }) => (
          <p style={{ textAlign: "left" }} {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ textAlign: "left" }} {...props} />
        ),
        pre: ({ node, ...props }) => (
          <pre style={{ textAlign: "left" }} {...props} />
        )
      }}
    />
  );
  useEffect(() => {

    const fromInterpretationScreen =
      sessionStorage.getItem("fromInterpretationScreen") === "true";
    const summary = sessionStorage.getItem("summary");

    if (fromInterpretationScreen && summary) {
      const summarySteps = summary.split("\n");
      const containsNumbers = summarySteps.some((step) =>
        /^\s*\d+\.\s+/.test(step)
      );

      const botMessage = {
        message: (
          <div>
            {containsNumbers ? (
              summarySteps?.map((step, index) => (
                <p style={{ textAlign: "left" }} key={index}>
                  {step.trim()}
                </p>
              ))
            ) : (
              <p style={{ textAlign: "left" }}>{summarySteps}</p>
            )}
          </div>
        ),
        sender: "bot",
        question_summary: "Analysis summary for log",  //description
        chat_history: summary,
        original_answer: "",
        answer_context: "",
        rag_triad: "",
      };
      let newM = [{}];
      newM.push(botMessage);
      setMessages(newM); // Initialize messages with the bot summary message
      scrollToBottom();

      const newChatObject = {
        id: uuidv4(),
        chats: newM, //// Initialize the chat with the bot summary message
      };
      let newChatList = [...chatsObjectsList];
      newChatList.push(newChatObject);
      setChatsObjectsList(newChatList); // Initialize chats objects list with the new chat
      setSelectedChat(newChatObject); // Set the new chat as the selected chat
    }
    clearSessionStorage();
  }, [chatsObjectsList]);

  const clearSessionStorage = () => {
    sessionStorage.removeItem("fromInterpretationScreen");
    sessionStorage.removeItem("summary");
    sessionStorage.removeItem("description");
  };

  const handleSendMessage = () => {
    setLoading(true);

    let newMessage = {
      message: textAreaMessage,
      sender: sender,
    };

    if (!initialized.current) {
      initialized.current = true;
      newMessage = {
        message: question,
        sender: "user",
      };
    }

    let newM = [...messages];
    newM.push(newMessage);
    setMessages(newM);
    setTextAreaMessage("");
    setIsDisabled(true);

    const URL = "https://jyoti-api.octo-caleb.com/api/chat";

    const lastBotJson = messages?.filter((m) => {
      return m.sender === "bot";
    });

    const chatHistoryLastJson =
      lastBotJson[lastBotJson.length - 1]?.chat_history;
    let data = {};
    if (chatHistoryLastJson !== "") {
      data = {
        message: newMessage.message,
        chat_history: chatHistoryLastJson,
      };
    } else {
      data = {
        message: newMessage.message,
      };
    }

    axios
      .post(URL, data)
      .then((response) => {
        setLoading(false);
        let summary = "";
        let chatHistory = "";
        if (response.data.question_summary) {
          summary = response.data.question_summary;
        } else {
          summary = "";
        }
        if (response.data.chat_history) {
          chatHistory = response.data.chat_history;
        } else {
          chatHistory = "";
        }

        const botResponseSteps = response.data.bot_response.split("\n");
        const containsNumbers = botResponseSteps.some((step) =>
          /^\s*\d+\.\s+/.test(step)
        );

        const containsCode = /```/.test(response.data.bot_response); //to check if it contains code

        const botMessage = {
          message: (
            <div>
              {/* {containsNumbers ? (
              botResponseSteps?.map((step, index) => (
                <p style={{ textAlign: "left" }} key={index}>
                  {step.trim()}

                </p>
              ))
            ) : (
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {botResponseSteps}
              </p>
            )} */}

              {containsCode ? (
                renderMarkdown(response.data.bot_response)
              ) : containsNumbers ? (
                botResponseSteps.map((step, index) => (
                  <p style={{ textAlign: "left" }} key={index}>
                    {step.trim()}
                  </p>
                ))
              ) : (
                <p style={{ textAlign: "left" }}>{botResponseSteps}</p>
              )}
            </div>
          ),
          sender: "bot",
          question_summary: summary,
          chat_history: chatHistory,
          original_answer: response.data.bot_response,
          answer_context: response.data.answer_context,
          rag_triad: response.data.rag_triad,
          response_time: response.data.response_time
        };
        let updatedMessages = [...newM, botMessage];
        setMessages(updatedMessages);
        scrollToBottom();

        if (selectedChat) {
          const updatedValues = {
            chats: updatedMessages,
          };

          const updatedList = chatsObjectsList.map((obj) => {
            if (obj.id === selectedChat.id) {
              return { ...obj, ...updatedValues };
            }
            return obj;
          });
          setChatsObjectsList(updatedList);
        } else {
          const newChatObject = {
            id: uuidv4(),
            chats: updatedMessages,
          };
          let oldChatsList = [...chatsObjectsList];
          oldChatsList.push(newChatObject);
          setChatsObjectsList(oldChatsList);
          setSelectedChat(newChatObject);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handletextArea = (e) => {
    setTextAreaMessage(e.target.value);
    setSender("user");
    if (e.target.value === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const handleLeftTab = (id) => {
    const filteredChat = chatsObjectsList?.find((chat) => chat?.id === id);
    // const chats = filteredChat?.chats;
    const chats = filteredChat ? filteredChat.chats : [];
    setSelectedChat(filteredChat);
    setMessages(chats);
  };

  const handleNewChatButton = () => {
    setMessages([]);
    const newChatObject = {
      id: uuidv4(),
      chats: [],
    };

    let oldChatsList = [...chatsObjectsList];
    oldChatsList.push(newChatObject);
    setChatsObjectsList(oldChatsList);
    setSelectedChat(newChatObject);
  };

  const handleDeletebutton = (e, id) => {
    e.stopPropagation();
    setSelectedChat(null);
    setMessages([]);
    const itemToKeep = chatsObjectsList.filter((obj) => obj.id !== id);
    setChatsObjectsList(itemToKeep);
  };

  const handleCopy = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => console.log("copied"))
      .catch((error) => console.error("Failed to copy:", error));
  };

  const handleContextButton = (answer_context) => {
    setIsModalopen(true);
    setAnswerContext(answer_context);
  };

  const handleUserFeedback = (event) => {
    let containsSelectedClass =
      event.currentTarget.classList.contains("selected-feedback");
    const icons = event.currentTarget.parentNode.parentElement.querySelectorAll(
      '[data-id="user-feedback"]'
    );
    icons.forEach((icon) => {
      icon.classList.remove("selected-feedback");
    });
    if (!containsSelectedClass) {
      event.currentTarget.classList.add("selected-feedback");
    }
  };

  return (
    <div className="chat-window-container">
      {loading && (
        <div className="overlay">
          <BouncingDotsLoader />
        </div>
      )}
      {isModalopen && (
        <ContextModal
          setIsModalopen={setIsModalopen}
          isModalopen={isModalopen}
          placement={"end"}
          answerContext={answerContext}
        />
      )}

      <div className="chat-history">
        <nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse">
          <div className="position-sticky">
            <div className="title-button-new-chat">
              <text className="chat-history-title">Chat history</text>

              <div>
                <Tooltip id="add-tooltip" style={{ padding: "5px" }} />
                <button
                  data-tooltip-id="add-tooltip"
                  data-tooltip-content="New chat"
                  data-tooltip-place="bottom"
                  className={`newChatButton ${loading ? "disabled" : ""}`}
                  onClick={handleNewChatButton}
                  disabled={loading}
                >
                  <IoMdAdd className="add-btn" />
                </button>
              </div>
            </div>

            <div className="list-group list-group-flush mt-4">
              {chatsObjectsList?.map((chat, id) => {
                return (
                  <div className="button-tab-window" key={id}>
                    <button
                      className="list-group-item list-group-item-action py-2 ripple position-relative d-flex align-items-center"
                      aria-current="true"
                      onClick={() => handleLeftTab(chat.id)}
                      disabled={loading}
                    >
                      <span className="flex-grow-1">
                        {chat?.chats[1]?.question_summary
                          ? chat?.chats[1]?.question_summary
                          : "New chat"}
                      </span>
                      <div className=" ms-2">
                        <Tooltip
                          id="delete-tooltip"
                          style={{ padding: "5px" }}
                        />
                        <AiFillDelete
                          data-tooltip-id="delete-tooltip"
                          data-tooltip-content="Delete"
                          data-tooltip-place="bottom"
                          onClick={(e) => handleDeletebutton(e, chat.id)}
                          className={`deleteButton ${loading ? "disabled" : ""
                            }`}
                          disabled={loading}
                        />
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </nav>
      </div>
      <div className="chat-window">
        <div className="message-list" ref={scrollableDiv}>
          {messages?.map((message, index) => (
            <>
              <div key={index} className={`message ${message.sender}`}>
                {message.message}
                {message.sender === "bot"  && (
                  <span className="response-rag-span">
                    {message.response_time && <span className="response-time-container-inside">
                      Response Time: {message.response_time} sec
                    </span>}
                    {message.rag_triad && <span className="rag-triad-container-inside">
                      Relevance: Context: {formatScore(message.rag_triad.context_relevance)},
                      Groundedness: {formatScore(message.rag_triad.groundedness_measure)}
                    </span>}
                  </span>
                )}
              </div>
              {message.sender === "bot" && (
                <div style={{ display: "flex" }}>
                  <div className="copy-icon-container">
                    <div>
                      <Tooltip id="copy-tooltip" style={{ padding: "5px" }} />
                      <FiClipboard
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="Copy"
                        data-tooltip-place="bottom"
                        onClick={() => handleCopy(message.original_answer)}
                      />
                    </div>
                    {message.answer_context && (
                      <div>
                        <Tooltip
                          id="context-tooltip"
                          style={{ padding: "5px" }}
                        />
                        <HiOutlineViewList
                          className="context-button"
                          data-tooltip-id="context-tooltip"
                          data-tooltip-content="Source"
                          data-tooltip-place="bottom"
                          onClick={() =>
                            handleContextButton(message.answer_context)
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="feedback-container">
                    <div>
                      <Tooltip
                        id="thumbs-up-tooltip"
                        style={{ padding: "5px" }}
                      />
                      <FaThumbsUp
                        onClick={handleUserFeedback}
                        data-id="user-feedback"
                        data-tooltip-id="thumbs-up-tooltip"
                        data-tooltip-content="Like"
                        data-tooltip-place="bottom"
                      />
                    </div>
                    <div>
                      <Tooltip
                        id="thumbs-down-tooltip"
                        style={{ padding: "5px" }}
                      />
                      <FaThumbsDown
                        onClick={handleUserFeedback}
                        data-id="user-feedback"
                        data-tooltip-id="thumbs-down-tooltip"
                        data-tooltip-content="Dislike"
                        data-tooltip-place="bottom"
                      />
                    </div>
                    <div>
                      <Tooltip id="light-tooltip" style={{ padding: "5px" }} />
                      <FaLightbulb
                        onClick={handleUserFeedback}
                        data-id="user-feedback"
                        data-tooltip-id="light-tooltip"
                        data-tooltip-content="Solved"
                        data-tooltip-place="bottom"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="input-area">
          <textarea
            className="textarea-input"
            type="text"
            placeholder="Type your message..."
            onChange={handletextArea}
            value={textAreaMessage}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={loading}
          />

          <div>
            <Tooltip id="send-tooltip" style={{ padding: "5px" }} />
            <button
              data-tooltip-id="send-tooltip"
              data-tooltip-content="Send"
              data-tooltip-place="bottom"
              className={`send-button ${loading ? "disabled" : ""}`}
              onClick={handleSendMessage}
              disabled={isDisabled}
            >
              <IoMdSend />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
