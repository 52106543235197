import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
    Box,
    DataTable,
    Heading,
    ResponsiveContext,
    Layer,
    Button,
    Header,
    Paragraph,
    Text,
    Anchor,
    Spinner,
} from "grommet";
import { Close, BladesVertical } from "grommet-icons";
import BouncingDotsLoader from "../BouncingDotsLoader";

const LogInterpretation = () => {
    const [rowClicked, setRowClicked] = useState(false);
    const [prcData, setPrcData] = useState([]);
    const [logBySelectedEvent, setlogBySelectedEvent] = useState([]);
    const size = React.useContext(ResponsiveContext);
    const [isSummaryclicked, setIsSummaryclicked] = useState(false);
    const [selectedPRCObject, setSelectedPRCObject] = useState({});
    const [showLogDetails, setShowLogDetails] = useState(false);
    const [selectedLog, setselectedLog] = useState([]);
    const [showLogTable, setShowLogTable] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [showContent, setShowContent] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            property: "prc_desc",
            header: "PRC Desc",
        },
        {
            property: "prc_timestamp",
            header: "Timestamp",
        },
        {
            property: "",
            header: "Details",
            render: (rowData) => (
                <Button onClick={(e) => handlePRCSummary(e, rowData)}>
                    <BladesVertical />
                </Button>
            ),
        },
    ];

    const logscolumns = [
        {
            property: "message",
            header: "Message",
        },
        {
            property: "source",
            header: "Source",
        },
        {
            property: "",
            header: "Detail",
            render: (rowData) => (
                <Button onClick={(e) => handleLogInfoIcon(e, rowData)}>
                    <BladesVertical />
                </Button>
            ),
        },
    ];

    const handleClickRow = (obj) => {
        setSelectedRow(obj.prc_id);
        setShowLogTable(true);
        setLoaderText("Loading logs....");
        setSpinner(true);
        const prc_id = obj.prc_id;
        const eventbyIdURL =
            "https://jyoti-api.octo-caleb.com/api/events/" + prc_id + "/logs";

        axios
            .get(eventbyIdURL)
            .then((response) => {
                setlogBySelectedEvent(response.data);
                setSpinner(false);
            })
            .catch((error) => {
                console.log("error", error);
                setSpinner(false);
            });
    };

    useEffect(() => {
        setSpinner(true);
        setLoaderText("Loading PRCs....");
        const URL = "https://jyoti-api.octo-caleb.com/api/events";

        axios
            .get(URL)
            .then((response) => {
                console.log("response", response.data);
                setPrcData(response.data);
                setSpinner(false);
            })
            .catch((error) => {
                console.log("error", error);
                setSpinner(false);
            });
    }, []);

    const handlePRCSummary = (e, obj) => {
        e.stopPropagation();
        setRowClicked(true);
        setIsSummaryclicked(true);
        setSelectedPRCObject(obj);
        setTimeout(() => {
            setShowContent(true);
        }, 2000);
    };

    const handleLogInfoIcon = (e, obj) => {
        e.stopPropagation();
        setShowLogDetails(true);
        setselectedLog(obj);
        setTimeout(() => {
            setShowContent(true);
        }, 2000);
    };

    const pointWiseSummary = selectedPRCObject?.summary?.split("\n");
    const containsNumbers = pointWiseSummary?.some((step) =>
        /^\s*\d+\.\s+/.test(step)
    );

    const logSummaryPointwise = selectedLog?.summary?.split("\n");
    const containsPoints = logSummaryPointwise?.some((step) =>
        /^\s*\d+\.\s+/.test(step)
    );

    const styles = {
        Anchor: {
            wordWrap: "break-word",
            wordBreak: "break-all",
        },
        Paragraph: {
            wordWrap: "break-word",
        }
    };

    const handleCloseSituationPRC = () => {
        setRowClicked(false);
        setShowContent(false);
    };

    const handleCloseSituationLog = () => {
        setShowLogDetails(false);
        setShowContent(false);
    };

    const renderSummaryLoader = () => {
        return (
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Text>Loading Summary....</Text>
                <BouncingDotsLoader />
            </Box>
        )
    }

    const ChatButton = (description, summary) => {
        return (
            <Button
                label="Chat"
                style={{ width: "80px" }}
                primary
                onClick={() => handleChatButton(description, summary)}
                alignSelf="center"
                margin={{ top: "medium", left: "medium" }}
            />
        )
    }

    const handleChatButton = (description, summary) => {
        // navigate('/', { state: { fromInterpretationScreen: true, description:description,summary: summary } });
        sessionStorage.setItem('fromInterpretationScreen', true);
        sessionStorage.setItem('description', description);
        sessionStorage.setItem('summary', summary);
        navigate('/');
    }

    return (
        <Box
            style={{
                marginLeft: "9%",
                marginRight: "9%",
                marginTop: "10px",
                marginBottom: "10px",
            }}
        >
            {spinner && (
                <Layer>
                    <Box
                        align="center"
                        justify="center"
                        gap="small"
                        direction="row"
                        alignSelf="center"
                        pad="large"
                    >
                        <Spinner />
                        <Text> {loaderText} </Text>
                    </Box>
                </Layer>
            )}
            {rowClicked && (
                <Layer
                    position={!["xsmall", "small"].includes(size) ? "right" : undefined}
                    full={["xsmall", "small"].includes(size) ? true : "vertical"}
                    onClickOutside={handleCloseSituationPRC}
                    onEsc={handleCloseSituationPRC}
                >
                    <Box
                        fill="vertical"
                        gap="medium"
                        pad={{ vertical: "medium" }}
                        width={{
                            min: !["xsmall", "small"].includes(size) ? "medium" : undefined,
                        }}
                    >
                        <Box flex overflow="auto">
                            <Header
                                align="start"
                                pad={{ horizontal: "medium", bottom: "medium" }}
                                style={{ marginTop: "50px" }}
                            >
                                <Button
                                    a11yTitle="Close filter layer"
                                    icon={<Close />}
                                    onClick={handleCloseSituationPRC}
                                    alignSelf="end"
                                    margin={{ left: "auto", right: "medium" }}
                                />
                            </Header>
                            <Box pad={{ horizontal: "medium", bottom: "medium" }}>

                                {showContent ? (
                                    <>
                                        <Heading marginBottom="5px" level={2} size="small">
                                            PRC description:
                                        </Heading>
                                        <Paragraph>{selectedPRCObject?.prc_desc}</Paragraph>
                                        <Heading marginBottom="5px" level={2} size="small">
                                            Summary:
                                        </Heading>
                                        {isSummaryclicked && (
                                            <>
                                                {containsNumbers ? (
                                                    pointWiseSummary?.map((step, index) => (
                                                        <Paragraph key={index}>{step.trim()}</Paragraph>
                                                    ))
                                                ) : (
                                                    <Paragraph>{selectedPRCObject?.summary}</Paragraph>
                                                )}
                                            </>
                                        )}
                                        {ChatButton(selectedPRCObject?.prc_desc, selectedPRCObject?.summary)}

                                    </>
                                ) : renderSummaryLoader()}
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )}

            {showLogDetails && (
                <Layer
                    position={!["xsmall", "small"].includes(size) ? "right" : undefined}
                    full={["xsmall", "small"].includes(size) ? true : "vertical"}
                    onClickOutside={handleCloseSituationLog}
                    onEsc={handleCloseSituationLog}
                >
                    <Box
                        fill="vertical"
                        gap="medium"
                        pad={{ vertical: "medium" }}
                        width={{
                            min: !["xsmall", "small"].includes(size) ? "medium" : undefined,
                        }}
                    >
                        <Box flex overflow="auto">
                            <Header
                                align="start"
                                pad={{ horizontal: "medium", bottom: "medium" }}
                                style={{ marginTop: "50px" }}
                            >
                                <Button
                                    a11yTitle="Close filter layer"
                                    icon={<Close />}
                                    onClick={handleCloseSituationLog}
                                    alignSelf="end"
                                    margin={{ left: "auto", right: "medium" }}
                                />
                            </Header>
                            <Box
                                pad={{ horizontal: "medium", bottom: "medium" }}
                                style={styles.Paragraph}
                            >
                                {showContent ? (
                                    <>
                                        <Heading marginBottom="5px" level={2} size="small">
                                            Message:
                                        </Heading>
                                        <Paragraph>{selectedLog?.message}</Paragraph>
                                        <Heading marginBottom="5px" level={2} size="small">
                                            Source:
                                        </Heading>
                                        <Paragraph>{selectedLog?.source}</Paragraph>
                                        <Heading marginBottom="5px" level={2} size="small">
                                            Summary:
                                        </Heading>
                                        {
                                            <>
                                                {containsPoints ? (
                                                    logSummaryPointwise?.map((step, index) => (
                                                        <Paragraph key={index}>{step.trim()}</Paragraph>
                                                    ))
                                                ) : (
                                                    <Paragraph>{selectedLog?.summary}</Paragraph>
                                                )}
                                            </>
                                        }

                                        <Box gap="medium">
                                            {selectedLog?.rag_answer_relevance && (
                                                <>
                                                    <Heading margin="none" level={2} size="small">
                                                        RAG answer relevance:{" "}
                                                        <Text
                                                            style={{ fontWeight: 400, paddingLeft: "10px" }}
                                                        >
                                                            {selectedLog?.rag_answer_relevance}
                                                        </Text>
                                                    </Heading>
                                                </>
                                            )}

                                            {selectedLog?.rag_context_relevance && (
                                                <>
                                                    <Heading margin="none" level={2} size="small">
                                                        RAG context relevance:{" "}
                                                        <Text
                                                            style={{ fontWeight: 400, paddingLeft: "10px" }}
                                                        >
                                                            {selectedLog?.rag_context_relevance}
                                                        </Text>
                                                    </Heading>
                                                </>
                                            )}

                                            {selectedLog?.rag_groundness && (
                                                <>
                                                    <Heading margin="none" level={2} size="small">
                                                        RAG groundness:{" "}
                                                        <Text
                                                            style={{ fontWeight: 400, paddingLeft: "10px" }}
                                                        >
                                                            {selectedLog?.rag_groundness}
                                                        </Text>
                                                    </Heading>
                                                </>
                                            )}

                                            {selectedLog?.sources && (
                                                <>
                                                    <Heading margin="none" level={2} size="small">
                                                        Sources:
                                                    </Heading>
                                                    {selectedLog?.sources?.map((source) => {
                                                        return (
                                                            <Paragraph style={styles.Anchor}>
                                                                <Anchor href={source} target="_blank">
                                                                    {source}
                                                                </Anchor>
                                                            </Paragraph>
                                                        );
                                                    })}
                                                </>
                                            )}
                                            {ChatButton(selectedLog?.message,selectedLog?.summary)}
                                        </Box>
                                    </>
                                ) : (
                                    renderSummaryLoader()
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )}

            <Box gap="medium" align="start" fill>
                <Heading
                    id="storage-pools-heading"
                    level={2}
                    margin={{ bottom: "small", top: "medium" }}
                    style={{ alignContent: "center" }}
                >
                    Probable Root Causes
                </Heading>
                <Box height="auto" overflow="auto" fill>
                    <DataTable
                        aria-describedby="storage-pools-heading"
                        data={prcData}
                        columns={[
                            {
                                property: "prc_id",
                                header: "PRC Id",
                                primary: true,
                                pin: ["xsmall", "small"].includes(size),
                            },
                            ...columns,
                        ]}
                        fill="horizontal"
                        onClickRow={({ datum }) => handleClickRow(datum)}
                        pin
                        paginate={{
                            border: "top",
                            direction: "row",
                            fill: "horizontal",
                            flex: false,
                            justify: !["xsmall", "small"].includes(size) ? "end" : "center",
                            pad: { top: "xsmall" },
                        }}
                        border={"top"}
                        step={8}
                        rowProps={{
                            [selectedRow]: {
                                background: "light-3", // Use the same color as hover or any other color you want
                            },
                        }}
                    />
                </Box>

                {showLogTable && (
                    <Box align="start" gap="medium" fill>
                        <Heading
                            id="storage-pools-heading"
                            level={2}
                            margin={{ bottom: "small", top: "medium" }}
                            style={{ alignContent: "center" }}
                        >
                            Related Log Entries
                        </Heading>
                        <Box
                            height="large"
                            overflow="auto"
                            style={{ marginBottom: "50px" }}
                            fill
                        >
                            <DataTable
                                aria-describedby="storage-pools-heading"
                                data={logBySelectedEvent}
                                columns={[
                                    {
                                        // property: 'message',
                                        // header: 'Message',
                                        primary: true,
                                        pin: ["xsmall", "small"].includes(size),
                                    },
                                    ...logscolumns,
                                ]}
                                fill
                                pin
                                paginate={{
                                    border: "top",
                                    direction: "row",
                                    fill: "horizontal",
                                    flex: false,
                                    justify: !["xsmall", "small"].includes(size)
                                        ? "end"
                                        : "center",
                                    pad: { top: "xsmall" },
                                }}
                                step={5}
                                border={"top"}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default LogInterpretation;
